import { EditOutlined } from '@ant-design/icons';

import {
  Select,
  Button,
  Form,
  message,
  Space,
  Steps,
  Typography,
  DatePicker,
  Tag,
  Input, Modal
} from 'antd';

import { useEffect, useMemo, useState } from 'react';

import PageTitle from '../../../components/layout/page-title/PageTitle';
import MyGoalsService from '../../../services/api/MyGoalsService';
import MyGoalsListTable from './components/MyGoalsListTable';
import moment from 'moment';

import './styles.less';
import FormItem from 'antd/lib/form/FormItem';
const { Option } = Select;

const MyGoalsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.submit();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment());
  const [pleaseUpdate, setPleaseUpdate] = useState({});

  useEffect(() => {
    setLoading(true);
    MyGoalsService.getAllGoals()
      .then((res) => res.data.data)
      .then((data) => {
        console.log("DATA ", data.myGoals);
        setData(data);
        // const withKeyData = data.lifeMastery.map((item) => ({
        //   ...item,
        //   key: item.id,
        // }));
        // const groupedByDay = groupBy(withKeyData, (item) => {
        //   return moment(item.createdAt);
        // });
        // checkExist(groupedByDay, date);
      })
      .finally(() => setLoading(false));
  }, [date, pleaseUpdate]);

  const dateIsUpdated = (date) => {
    setDate(date);
  };

  const onFinish = async (values) => {
    const dateNotValid = moment(moment(values.start)).isSameOrAfter(
      moment(values.deadline),
    );

    if (dateNotValid) {
      message.error('Starting date must not be equal or greater than Deadline');
    } else {
      setLoading(true);
      try {
        if (editMode) {
          await MyGoalsService.updateGoal({ ...values, userId: currentData.userId, id: currentData.id });
          message.success('Your goal has been saved.');
          form.resetFields();
          setLoading(false);
          setPleaseUpdate({});
          setEditMode(false);
          handleOk();

        } else {
          await MyGoalsService.addGoal(values);
          message.success('Your goal has been saved.');
          form.resetFields();
          setLoading(false);
          setPleaseUpdate({});
          setEditMode(false);
          handleOk();
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const editingAlert = editMode ? (
    <Tag
      icon={<EditOutlined />}
      color="orange"
      style={{ marginLeft: '2%', marginBottom: '2%' }}
    >
      You are currently editing previous data
    </Tag>
  ) : null;

  const addNewBtn = (
    <Button
      type="primary"
      style={{ marginLeft: '2%', marginBottom: '2%', float: 'right' }}
      onClick={() => {
        showModal();
        form.resetFields();
        setDate(moment());
        setCurrentData({});
        setData({});
        setEditMode(false);
      }}
    // icon={<LeftOutlined />}
    // disabled={}
    >
      Add New
    </Button>
  );

  const submitBtn = (
    <Button type="primary" htmlType="submit" loading={loading}>
      Submit
    </Button>
  );

  const setFormData = (data) => {

    form.resetFields();
    form.setFieldsValue({ ...data, start: moment(data.start), deadline: moment(data.deadline) });
    setCurrentData(data);
    setEditMode(true);
    showModal();

  };

  return (
    <>
      <PageTitle title="My Goals" />




      {addNewBtn}
      {editingAlert}
      {loading ? null : (
        <DatePicker
          value={date}
          style={{ marginBottom: '5%', display: 'none' }}
          dateRender={(current) => {
            const style = {};

            return (
              <div className="ant-calendar-date" style={style}>
                {current.date()}
              </div>
            );
          }}
          defaultValue={date}
          onChange={dateIsUpdated}
        />
      )}

      <Modal title="Basic Modal" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>


        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className="mygoals">
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please goal title',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please goal description',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <FormItem
              label="Starting Date"
              name="start"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Starting Date',
                },
              ]}
            >
              <DatePicker></DatePicker>
            </FormItem>
            <FormItem
              label="Deadline"
              name="deadline"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Deadline Date',
                },
              ]}
            >
              <DatePicker></DatePicker>
            </FormItem>
            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Status"
                //   onChange={onGenderChange}
                allowClear
              >
                <Option value="todo">To Do	</Option>
                <Option value="inprogress">In Progress</Option>
                <Option value="done">Done</Option>
              </Select>
            </Form.Item>
          </div>
          {/* <Space>{submitBtn}</Space> */}
        </Form>
      </Modal>

      {
        data ?
          <MyGoalsListTable
            // step={currentStep}
            onEditClick={setFormData}
            wholedata={data.myGoals}
            pleaseUpdate={setPleaseUpdate}

          />
          : null
      }
    </>
  );
};

export default MyGoalsPage;
