import React from 'react';
import moment from 'moment';
import { Line } from '@ant-design/charts';

const AnswersChart = ({ data }) => {
  console.log(data);
  const CHART_COLOR = '#a700ba';
  data.map((item) => {
    if (item.date) {
      item.date = moment(item.date).format('MMM Do YY');

    }
  });
  data = data.filter(value => Object.keys(value).length !== 0);

  data.map((item) => {
    item.value = Math.round(item.value * 100) / 100;

  })


  const config = {
    data,
    xField: 'date',
    yField: 'value',
    xAxis: {
      label: {
        formatter: (x) => {
          return moment(x).format('MMM Do YY');
        },
      },
    },
    label: {},
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: CHART_COLOR,
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
    color: CHART_COLOR,
  };

  return <Line {...config} />;
};

export default AnswersChart;
