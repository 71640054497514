import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  message,
  Row,
  Spin,
  DatePicker,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ConverUnit from '../../../components/inputs/ConverUnit';
import Question from '../../../components/inputs/Question';
import PageTitle from '../../../components/layout/page-title/PageTitle';
import { DebounceSelect } from '../../../components/wrappers/inputs';
import AnswerService from '../../../services/api/AnswerService';
import QuestionService from '../../../services/api/QuestionService';
import AnswersPage from '../../tables/answers/AnswersPage';
import AnswersChart from '../../../pages/tables/answers/components/AnswersChart';
import { convertToShow } from '../../../components/inputs/ConverUnit';
import _ from "lodash";


import './styles.less';
import moment from 'moment';

const { Panel } = Collapse;

const MyHealthWellnessData = () => {
  const [createLoading, setCreateLoading] = useState(false);
  const [convertedValue, setConvertedValue] = useState(undefined);
  const [isModifying, setModifying] = useState(false);
  const [currentEditingData, setCurrentEditingData] = useState({});
  const [pleaseUpdate, setPleaseUpdate] = useState({});


  const [QAList, setQAList] = useState(undefined);

  const [date, setDate] = useState(moment());

  const [selectedQuestionGroupId, setSelectedQuestionGroupId] =
    useState(undefined);

  const [questions, setQuestions] = useState([]);
  const [selectedQuestionItem, setSelectedQuestionItem] = useState(undefined);

  const [answer, setAnswer] = useState(undefined);

  const [form] = Form.useForm();

  useEffect(() => {
    setConvertedValue(undefined);

    loadQA();
    getQuestions();

  }, [pleaseUpdate]);

  const loadQA = async () => {
    const res = await AnswerService.getAllAnswersById();
    let dataSort = res.data.data.answers.sort(
      (a, b) => a.createdAt - b.createdAt,
    );
    setQAList(dataSort);
  };

  const onFinish = async (values) => {
    if (convertedValue) {
      values = { [Object.keys(values)[0]]: convertedValue };
    }

    setCreateLoading(true);
    if (isModifying) {
      try {

        await AnswerService.editAnswer(currentEditingData.id, values);
        message.success('Your answer has been saved.');
        resetQuestionAndAnswer();
        setPleaseUpdate();
        window.location.reload();


      } catch (error) {
        message.error(error.message);
      }
    } else {
      try {
        const withKeyData = ({
          ...values,
          createdAt: date,
        });


        await AnswerService.createNewAnswer(withKeyData);
        message.success('Your answer has been saved.');
        resetQuestionAndAnswer();
        setPleaseUpdate();
        window.location.reload();

      } catch (error) {
        message.error(error.message);
      }
    }

    setCreateLoading(false);
  };

  const setFormData = (data) => {
    form.setFieldsValue({
      [data.question.id]: JSON.parse(data.answer).value,
    });
    // setModifying(true);
  };

  const getQuestions = useCallback(
    async (searchTerm = '') => {
      const res = await QuestionService.getAllQuestions(
        searchTerm,
        selectedQuestionGroupId,
      );

      const mapped = res.data.data.questions.map((item) => ({
        label: item.title,
        value: item.id,
        groupName: item.groupName,
      }));
      setQuestions(res.data.data.questions);

      return mapped;
    },
    [selectedQuestionGroupId],
  );

  const selectedQuestion = useMemo(() => {

    const question = questions.find((q) => q.id === selectedQuestionItem?.key);


    if (!question) {
      return undefined;
    }

    let output;
    const { options } = question;
    if (options) {
      output = { ...question, options: JSON.parse(question.options) };
    } else {

      output = { ...question };

    }

    return output;
  }, [questions, selectedQuestionItem]);

  const questionSelect = (
    <DebounceSelect
      className="wellnesssearch"
      size={"large"}
      placeholder="Data Entry"
      fetchOptions={getQuestions}
      onChange={setSelectedQuestionItem}
      value={selectedQuestionItem}
      style={{
        width: '100%', paddingBottom: "10px"
      }}
      debounceTimeout={2000}
      showSearch
      withGroup
    />
  );

  const resetQuestionAndAnswer = () => {
    form.resetFields();
    setDate(moment())
    setModifying(false);
    setCurrentEditingData({});
    setSelectedQuestionGroupId(undefined);
    setSelectedQuestionItem(undefined);
  };

  const dateIsUpdated = useEffect(() => {
    // console.log("THIS IS DATE "+ moment(date).format());
    // const today = _.groupBy(QAList, d => moment(date).startOf('day').isSame(moment(d.createdAt).startOf('day')));
    let datefound = false;
    let dataPerDay = [];
    QAList?.forEach((fdata) => {
      // if(!datefound)
      // {
      if (fdata.question.id == selectedQuestionItem?.key) {
        // console.log(fdata.question);
        // console.log(selectedQuestionItem?.key);
        // console.log(moment(date).startOf('day'));
        // console.log(moment(fdata.createdAt).startOf('day'));
      }
      if (moment(date).startOf('day').isSame(moment(fdata.createdAt).startOf('day')) && (fdata.question.id == selectedQuestionItem?.key)) {
        dataPerDay.push(fdata);
        setFormData(fdata);
        datefound = true;
        setModifying(true);
        setCurrentEditingData(fdata);
      } else {
        form.resetFields();
        // setDate(date)
        setModifying(false);
        setCurrentEditingData({});
      }
    }

      // }



    );
    // console.log(dataPerDay);

    // console.log(QAList);

    // let appendedWords = QAList?.filter((fdata, index, arr) => {
    //   // arr.push('new');
    //   return arr;
    //     // return  moment(date).startOf('day').isSame(moment(fdata.createdAt).startOf('day'))&&(fdata.question.id == selectedQuestionItem?.key)


    // })

    // console.log(today);


  }, [date, selectedQuestion, pleaseUpdate]);

  let questionComponent = null;
  if (selectedQuestion) {
    questionComponent = (
      <Question
        {...selectedQuestion}
        onChange={(e) => setAnswer(e)}
        value={answer}
      />
    );
  }

  const hasChart = useMemo(() => {
    if (selectedQuestionItem) {
      if (!QAList.length) {
        return false;
      }
      let AnswersOnly = [];
      QAList.forEach(element => {
        if (element.answer && element.question.type === "number") {
          AnswersOnly.push(JSON.parse(element.answer)?.value)
        }
      });
      // const final = QAList.map((item) => JSON.parse(item.answer)?.value);
      // return final.every((item) => typeof item == 'number') && questions;
      return AnswersOnly;
    }
  }, [selectedQuestionItem]);

  const chart =
    hasChart && QAList && selectedQuestion != undefined ? (
      <div style={{ margin: '20px 0' }}>
        <AnswersChart
          data={QAList.map((item) => {

            let dataForChart = {};
            if (selectedQuestionItem?.key === item.question.id) {
              let d;
              d = JSON.parse(item.answer)?.value;

              if (item.question?.measure) {

                d = JSON.parse(item.answer)?.value;
                d = convertToShow(d, selectedQuestion.measure);
              } else {
                d = JSON.parse(item.answer)?.value;

              }
              dataForChart = {
                date: item.createdAt,
                value: d,
              };
            }
            return dataForChart;
          }).reverse()}
        />
      </div>
    ) : null;



  const hasMeasure =
    selectedQuestion &&
    selectedQuestion.type === 'number' &&
    selectedQuestion.measure;

  const formComponent = selectedQuestion ? (
    <Spin spinning={createLoading}>
      <Divider />

      <div style={{ marginTop: '20px' }} />

      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[2]}>
          <Col span={hasMeasure ? 22 : 24}>
            <Form.Item
              label={selectedQuestion.title}
              name={selectedQuestionItem.key}
              rules={[
                { required: true, message: 'Please answer this question!' },
              ]}
            >
              {questionComponent}
            </Form.Item>
          </Col>

          {hasMeasure ? (
            <Col span={2}>
              <Form.Item label=" ">
                <ConverUnit
                  measure={selectedQuestion?.measure}
                  value={form.getFieldValue(selectedQuestionItem.key)}
                  onChange={setConvertedValue}
                />
              </Form.Item>
            </Col>
          ) : null}
        </Row>

        <div style={{ marginTop: '20px' }} />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  ) : null;

  return (
    <div className="question-section">
      <PageTitle title="My Health & Wellness Data" />

      <Row gutter={[5]} >
        <Col span={22}>{questionSelect} </Col>
        {selectedQuestion ? (
          <DatePicker
            size='large'
            value={date}
            style={{ marginBottom: '5%', borderRadius: "30px" }}
            dateRender={(current) => {
              const style = {};
              return (
                <div className="ant-calendar-date" style={style}>
                  {current.date()}
                </div>
              );
            }}
            defaultValue={date}
            onChange={(date) => setDate(date)}
          />
        ) : null}
        <Col span={2}>
          <Button size='large' style={{ borderRadius: "30px" }} onClick={resetQuestionAndAnswer} block>
            Reset
          </Button>
        </Col>
      </Row>
      {formComponent ? (
        formComponent
      ) : (
        <div style={{ marginBottom: '180px' }} />
      )}
      {chart}
      <Collapse accordion style={{ marginTop: '20px' }}>
        <Panel header="History" key="1">
          {QAList ? <AnswersPage
            selectedQuestionItem={selectedQuestionItem}
            setSelectedQuestionItem={setSelectedQuestionItem} // SETS CURRENT PAGE QUESTION ITEM FROM INSIDE ANSWERPAGE
            questionGroupId={selectedQuestionGroupId}
            getAnswers={QAList}
            pleaseUpdate={setPleaseUpdate}

          /> : null}

        </Panel>
      </Collapse>
    </div>
  );
};

export default MyHealthWellnessData;
